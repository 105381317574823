import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10rem 1rem;
  text-align: center;
  div {
    margin-top: 2rem;
  }
  h5 {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.7rem;
    color: #3f464e;
  }
  @media (max-width: 480px) {
    padding: 5rem 1rem;
  }
`;
