import React from 'react';
import {Container} from './styles';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import Time from 'modules/subscription/icons/Time.svg';

const WaitingMessage = ({children}) => {
  return (
    <Container>
      <img src={Time} width={128} height={132} alt="loading" />
      {children}
      <div className="flex justify-center items-center spinner">
        <div className="dot-1" />
        <div className="dot-2" />
        <div />
      </div>
    </Container>
  );
};

export default WaitingMessage;
