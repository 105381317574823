import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useSelector, useDispatch} from 'react-redux';
import WaitingMessage from 'modules/common/WaitingMessage';
import {navigate} from 'gatsby';
import notification from 'core/libs/helpers/notification';
import SUBSCRIPTION from 'modules/user/graphql/subscription.graphql';
import {useQuery} from '@apollo/client';
import {saveIsTrialData} from 'modules/user/actions/selected-plan';

const CreditCardTokenization = () => {
  const authAndUser = useSelector((state) => state);
  const apiKey = authAndUser?.auth?.key;
  const userId = authAndUser?.user?.profile?.data?.id;
  const cardTokenization = authAndUser?.user?.selectedPlan;
  const selectedPlan = cardTokenization?.selectedPlan;
  const paymentInfo = cardTokenization?.paymentInfo;

  const [url3ds, setUrl3ds] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const cleanUrlParamsValue = (value) => {
    return value
      ? value.replace('[', '').replace(']', '').replaceAll("'", '')
      : null;
  };
  const tokenName = cleanUrlParamsValue(urlParams.get('token_name'));
  const responseMsg = cleanUrlParamsValue(urlParams.get('response_message'));

  const {data, loading} = useQuery(SUBSCRIPTION);
  const subscriptionData = data?.recurrentPayment;

  const dispatch = useDispatch();

  const handleError = ({message, id}) => {
    notification(message ? {message} : {id});
    navigate('/subscription', {
      state: {
        fromLink: window.history.state?.fromLink,
      },
    });
  };

  const processPayment = (createSub, headers, isAuthorize=false) => {
    let endpoint_uri = 'pay-first-amazon-subscription';
    let error_msg_id = 'payment_error_payment_failed';

    if (isAuthorize) {
      endpoint_uri = 'authorize-first-amazon-subscription';
      error_msg_id = 'payment_error_payment_authorization_failed';
    }

    dispatch(saveIsTrialData(isAuthorize));

    axios
      .post(
        `${process.env.GATSBY_API2}api/v1/billing/payment-v2/${createSub?.data?.id}/${endpoint_uri}/?apikey=${apiKey}`,
        headers,
      )
      .then((response) => {
        navigate('/subscription/message', {
          state: {
            fromLink: window.history.state?.fromLink,
          },
        });
      })
      .catch((error) => {
        if (error.response.status === 307) {
          const url_3d_secure = error.response?.data;
          setUrl3ds(url_3d_secure);
        } else {
          handleError({id: error_msg_id});
        }
      });
  };
  const createSubscription = (headers) => {
    axios
      .post(
        `${process.env.GATSBY_API2}api/v1/billing/subscription/create-subscription/?apikey=${apiKey}`,
        {
          plan_name: selectedPlan,
          payment_method: 'AMAZON',
        },
        headers,
      )
      .then((response) => {
        const isAuthorize = subscriptionData == null;
        processPayment(response, headers, isAuthorize);
      })
      .catch((error) => {
        handleError({id: 'server_error_try_again'});
      });
  };

  const createPaymentInfo = () => {
    const headers = {
      'Content-Type': 'application/json',
    };

    // Create payment info if the card is new
    if (paymentInfo !== 'oldPaymentInfo') {
      axios
        .post(
          `${process.env.GATSBY_API2}api/v1/billing/payment-v2/create-amazon-information/?apikey=${apiKey}`,
          {
            user_id: userId,
            token_name: tokenName,
          },
          headers,
        )
        .catch((error) => {
          handleError({id: 'server_error_try_again'});
        });
    }

    // Create the new subscription
    createSubscription(headers);
  };

  useEffect(() => {
    if (!loading)
      if (tokenName) {
        createPaymentInfo();
      } else {
        handleError({message: responseMsg});
      }
  }, [loading]);

  useEffect(() => {
    if (url3ds) {
      window.location.replace(url3ds);
    }
  }, [url3ds]);

  return (
    <WaitingMessage>
      <h3>المرجو الانتظار</h3>
      <h5 className="pt2">
        جاري تأكيد من بطاقة الاتمان, نرجوا منك الانتظار دون إغلاق هذه الصفحة.
        <br /> قد تستغرق عملية التأكيد مدة ٣ دقائق.
      </h5>
    </WaitingMessage>
  );
};

export default CreditCardTokenization;
